.featured  {
   width: 100%;
   position: relative;
   margin: 0 auto;
	@include tablet {
	}
   @include desktop {
      max-width: $desktop-medium;
	}
   @include md {
      max-width: $desktop-large;
	}

   &-action {
      padding: 2.0rem;
      width: 100%;
      margin: 0 auto;
      @include desktop {
         max-width: $tablet-wide;
   	}
      @include md {
         max-width: $desktop;
   	}
      
      &-item {
         &-center {
            display: block;
            text-align: center;
         }
         &-headline {   
            font-family: $headline2;
            color: $black;
         }
         &-text {
            display: block;
            font-family: $text;
            color: $darkgray;
            font-size: 1.25rem;
            margin-bottom: 1rem;
         }
      }
   }

   &-news {
      padding: 2.0rem 0;
      width: 100%;
      margin: 0 auto;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-content: flex-start;
      @include desktop {
         max-width: $tablet-wide;
      }
      @include md {
         max-width: $desktop;
   	}
      &-item {
         width: 45%;
         text-align: left;
         transition: $slow;
         &:hover {
            -moz-transform: scale(1.1);
            -webkit-transform: scale(1.1);
            -o-transform: scale(1.1);
            -ms-transform: scale(1.1);
            transform: scale(1.1);
         }
         &-center {
            width: 100%;
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-content: flex-start;
         }
         &-col {
            &:first-child {
               width: 160px;
            }
            &:last-child {
               margin: 1rem 0 1rem 1.5rem;
            }
         }
         &-pic {
            min-width: 160px;
         }
         &-img {
            width: 160px;
            height: auto;
            display: inline-block;
         }
         &-date {
            font-size: 0.9rem;
            text-transform: uppercase;
            color: $darkgray;
            font-weight: 700;
         }
         &-headline {
            @extend %headline4;
            color: $red;
         }
         &-text {
            display: block;
            color: $darkgray;
         }
         & .button.more {
            color: $red;
            left: -.25rem;
            &:after {
               background: $red;
               z-index: 1;
            }
         }
      }
   }


}
