.news-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: flex-start;
  &-item {
    width: 100%;
    padding: 1.5rem;
    transition: $slow;
    display: inline-block;
    @include tablet {
      width: 50%;
    }
    @include desktop {
      width: 33%;
    }
    &:hover {
      background: $lightestgray;
    }
    &-link {
      text-decoration: none !important;
    }
    &-headline {
      font-family: $headline2;
      color: $black;
      margin-bottom: 1rem;
      padding-bottom: .5rem;
      font-size: 1.45rem;
      line-height: 1.25;
      position: relative;
    }
    &-headline::after {
      content: "";
      width: 45%;
      height: 2px;
      position: absolute;
      bottom: -.25rem;
      left: -.5rem;
      z-index: 0;
      background: $yellow;
      @include desktop {
         height: 4px;
      }
    }
    &-description {
      display: block;
      font-family: $body;
      color: $darkgray;
	     font-weight: 300;
	     font-size: 70%;
    }
  }
}



.news-list-old {
  text-align: left;
  margin-top: 1rem;
  background: $lightestgray;
  @include tablet {
    margin-top: 1.25rem;
  }
  @include desktop {
    margin-top: 1.75rem;
  }
  @include lg {
    margin-top: 2rem;
  }  
  &:first-of-type {
    margin-top: 0;
  }
  &-header {
		background: $darkblue;
		color: $white;
		font-family: $headline;
		padding: .75rem .75rem;
		text-transform: uppercase;
		margin-bottom: .5rem;
		@extend %slide-up;	
		@include desktop {
		  font-size: 1.0rem;
		}
		&.reveal {
			@extend %slide-up-reveal;	
		}
	}
	&-item {
		@extend %slide-up;	
		&.reveal {
			@extend %slide-up-reveal;	
		}
	}
	&-title {
		font-family: $body;
	   font-weight: 300;
		@extend %headline6;	
		transition: $slow;
	}
	&-date {
	  text-transform: uppercase;
	  font-size: 80%;
	}
	&-link {
    color: $blue;
    transition: $slow;
		@extend %slide-up-reveal;	
		font-weight: 300;
		display: block;
		padding: .4rem;
    @include tablet {
		  padding: .6rem;
    }
    @include lg {
		  padding: .75rem;
    } 			
    &:hover {
      .news-list {
        &-title {
          color: $primary;
        }
      }
    }
		&:focus {
    	box-shadow: 0 0 0 2px $green;
      .news-list {
        &-title {
          color: $primary;
        }
      }    	
		}     
	}
}
