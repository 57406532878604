.folder-grid {
   margin: 2em 0;	
   width: 100%;
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   align-content: flex-start;
   &-item {
      width: 100%;
      display: block;
      padding: 0 0 1.5rem 0;
      text-align: center;
      &:nth-child(even) {
        margin: 0;
        padding: 0 0 1.5rem 0;
      }
      @include desktop {
        width: 50%;
        padding: 0 1.5rem 1.5rem 0;
      }
   }
   &-aspect {
      display: block !important;
      height: 100%;
      padding: 1.25em 1em;
      position: relative;
      text-decoration: none !important;
      background: $blue !important;
      transition: $slow;
      &:hover {
         background: $darkgray !important;
      }
      &:hover .folder-grid-headline {
         color: $lightblue !important;
      }
      &::after {
         content: "";
         width: 100%;
         height: 8px;
         position: absolute;
         bottom: 0;
         left: 0;
         z-index: 0;
         background: $orange; 
      }	
   }
   &-headline {
      display: block;
      font-family: $headline2 !important;
      color: $white !important;
      margin-bottom: 0 !important;
      font-size: 1.4rem !important;
   }
   &-description {
      display: block;
      color: $white;
      font-size: 1rem;
   }
   &.horizontal {
      margin: 0 0 2em 0;
      .folder-grid {
         &-item { 
            display: inline-block;
            margin: 0 .5rem;
            &:first-child {
               margin: 0 .5rem 0 0;
            }
            &:last-child {
               margin: 0 0 0 .5rem;
            }
         }
         &-aspect {
            display: inline-block !important;
            padding: 0;
            position: relative;
            top: initial;
            left: initial;
            text-transform: uppercase;
            text-decoration: none !important;
            &:hover {
               background: $clear !important;
               &::after {
                  content: "";
                  width: calc(100% + .5rem);
                  height: 4px;
                  position: absolute;
                  bottom: -.25rem;
                  left: -.25rem;
                  z-index: -1;
                  background: $yellow; 
               }
            }
            &:hover .folder-grid-headline {
               color: $black !important;
            } 
         }
         &-headline {
            display: inline-block;
            font-family: $nav !important;
            color: $darkgray !important;
            margin-bottom: 0 !important;
            font-size: 1.0rem !important;
         }
         &-description {
            display: none;
         }
      }
   }
}
