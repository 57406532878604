.text-layer {
	text-align: left;
	margin: 0 auto;
	@extend %block-padding;	
	opacity: 1;
	transition: $slow;
	@extend %basic-text;
	@extend %clearfix;	
	position: relative;
	@include phone {
	  padding-top: 1.5rem;
	  padding-bottom: 1.5rem;
	}
	&.small-padding {
  	@extend %block-padding-small;	
	}
	&.center {
	  text-align: center;
	}
	&.no_top_padding {
	  padding-top: 0 !important;
	}	
	&.no_bottom_padding {
	  padding-bottom: 0 !important;
    p:last-of-type {
      margin-bottom: 1rem;
    	@include tablet {
  			margin-bottom: 1.5rem;	
    	}
    	@include desktop {
  			margin-bottom: 1.4rem;	
    	}	
    	@include md {
  			margin-bottom: 1.6rem;	
  		
    	}		
    	@include lg {
  			margin-bottom: 1.8rem;	
    	}			
    	@include xl {
  			margin-bottom: 2rem;	
    	}		
    }
	}	
	&.top_margin {
	  @extend %block-margin-top;
	}	
	&.bottom_margin {
	  @extend %block-margin-bottom;
	}			

	&-wrap {
  	max-width: $desktop;
  	margin: 0 auto;
  	position: relative;
		@extend %clearfix;	
	  >*:last-child {
	    margin-bottom: 0;
	  }
   &.grid-33 {
     max-width: $desktop-medium;
   }

	}
	&-headline {
		color: $primary;
		text-align: center;
		line-height: 1;
		margin-bottom: 1rem;
    @extend %headline2;
    @include tablet {
      margin-bottom: 1.5rem;
    }
    @include desktop {
      margin-bottom: 2rem;
    }   
    &.trigger {
      @extend %slide-up;
      &.reveal {
        @extend %slide-up-reveal
      }
    }
	}


   h1 { 
      position: relative;
      font-family: $headline2;
      margin-bottom: .5em;
      
   }


	&.reveal {
		opacity: 1;				
	}
	a {
	  color: $lightblue;
      text-decoration: underline;
	  transition: $basic;
	  display: inline;
	  &:hover {
	    color: $white;
       background: $orange;
	  }
	}
	.button-block {
	  margin-top: 1rem;
	}
  ul, ol {
    margin-bottom: 1rem;
    @include tablet {
      margin-bottom: 1.5rem;
    }
    @include desktop {
      margin-bottom: 2rem;
    }    
  }
  p {
    &:last-of-type {
    }
  }
  .disclaimer {
    font-size: .8rem;
    text-align: center;
    margin-top: 1rem;
    display: block;
    @include tablet {
      margin-top: 1.5rem;
    }
    @include desktop {
      margin-top: 2rem;
    }       
  }  
  .video-player {
    margin: 0 auto;
    margin-bottom: 1rem;
    @include tablet {
      margin-bottom: 1.25rem;
    }
    @include desktop {
      margin-bottom: 1.75rem;
    }   
    @include lg {
      margin-bottom: 2rem;
    }     
  }
  &-image {
    position: relative;
    width: 100%;
    height: auto;
    @extend %basic-text;
    &-src {
      width: 100%;
      vertical-align: middle;
    }
    &.full-width {
      margin: 0 auto;
      margin-bottom: 1rem;
      max-width: $tablet;
      @include tablet {
        margin-bottom: 1.5rem;
        margin-top: 2rem;
      }
      @include desktop {
        margin-top: 3rem;
      }   
      @include xl {
        margin-top: 4rem;
      }        
    }
    &.floating-image {
      max-width: 100%;
      margin-bottom: 1rem;
      @include tablet {
        max-width: 50%;
        margin-bottom: 0;
        &.floating-image-left {
          float: left;
          margin: 0 1rem 1rem 0;
          @include tablet {
            margin: 0 1.5rem 1rem 0;
          }
          @include desktop {
            margin: 0 2rem 1rem 0;
          }          
        }        
      }
      @include tablet {
        max-width: 50%;
        &.floating-image-right {
          float: right;
          margin: 0 0 1rem 1rem;
          @include tablet {
            margin: 0 0 1rem 1.5rem;
          }
          @include desktop {
            margin: 0 0 1rem 2rem;
          }              
        } 
      }    
      &.intrinsic {
       max-width: none;
       width: auto;
       text-align: center;
       @include phone {
        float: none !important;
       }
       @include tablet {
        display: table !important;
       }
       .text-layer-image {
        &-src {
         margin: 0 auto;
         width: auto !important;
         max-width: 100%;
        }        
        &-caption {
         @include tablet {
          display: table-caption;
          caption-side: bottom;
          text-align: left;
         }
        }
       }
      }
    }
    &-caption {
     font-size: 70%;
     line-height: 1;
     padding: .25rem;
   		color: $gray;
   		@include desktop {
       padding: .25rem 0;
   		}
    }
  }
  &-embed {
    max-width: 100%;
    margin-bottom: 1rem;
    padding: 1rem;
   	opacity: 0;
   	transition: $slow;
    @include tablet {
      width: 40%;
      float: right;
      margin: 0 0 1rem 1.5rem;
    }
    @include desktop {
      width: 50%;
      margin: 0 0 1rem 2rem;
    }
    &-headline {
      margin-bottom: .5rem;
      font-family: $headline;
      font-weight: 600;
    }
    &.reveal {
      opacity: 1;
    }  
  }
}

article.clearfix {
   display: block;
   margin: 0 0 2rem 0;
   padding: 0 0 2rem 0;
   border-bottom: 1px $lightgray solid;
}

.list {
   margin: 2rem 0 1rem 0;
   display: flex !important;
   flex-wrap: wrap;
   flex-direction: row;
   align-items: stretch;
   align-content: stretch;
   
   &-item {
      flex: 1,1;
      width: 50%;
      margin: 0 0 2rem 0;
      &-link {
      }
      &-photo {
         display: block;
         margin: 0 1.5rem 0 0;
         & img {
            padding: 1px;
            width: 100%;
            border: .25rem solid $gray;
         }
      }
      &-text {
         display: block;
         padding: .25rem;
         & .headline {
            font-family: $headline;
            font-weight: 700;
            font-size: 1.5em;
            line-height: 1.2em;
            margin: 0;
            padding: 0;
         }
         & .description {
            color: $black;
            display: block;
            font-size: 0.9em;
            font-weight: normal;
            line-height: 1.35em;
            padding: .4em 0;
         }
      }
   }
}

.list-thumbnail {
   display: block;
   margin-top: 2rem;
   & .item {
      display: table;
   }
   & h3 {
      margin-bottom: .5rem;
   }
   & .icon {
      float: left;
      border: 5px solid #cecece;
      margin: 0 1.5em 2em 0;
   }
   & p {
      font-size: 1.1rem;
      line-height: 1.5rem;
   }
}

.bio-photo {
  float: right;
  width: 50%;
  margin: 0 0 1rem 1rem;
  @include tablet {
    float: right;
    width: 30%;
    margin: 0 0 2rem 2rem;
  }
  & img {
    max-width: 100%;
  }
}
