.hero {
	background-color: $medgray;
 @include size(100%, auto);	
 display: -webkit-box;
 display: -moz-box;
 display: -ms-flexbox;
 display: -webkit-flex;
 display: flex;
 -webkit-flex-flow: column-reverse;
 flex-flow: column-reverse;
 position: relative;
 margin: 0 auto;
	@include tablet-wide {
  flex-flow: initial;
		@include size(100%, 350px);
	}
 @include desktop {
		@include size(100%, 350px);
  max-width: $desktop-medium;
	}
 @include md {
  @include size(100%, 400px);
  max-width: $desktop-large;
	}

 &-text {
		z-index: 200;
		@include tablet-wide {
		width: 100%;
		padding: 0 1rem;	
		@include absolute(left 50% bottom 3rem);
		transform: translateX(-50%);			padding: 0 2rem;
			bottom: -2.5rem;		
		}		
		@include desktop {
		}	
		@include ie {
			width: auto !important;	
		}	
		@include edge {
			width: auto !important;	
		}
      &.align-bottom {
         top: initial;
         bottom: 2.5rem;
      }
	}
	&-pre-headline {
	 display: none;
	}
	&-headline {
		color: $white;
		font-family: $headline;
		line-height: 1;
		opacity: 1;
      position: relative;
      @extend %headline1;
      &-link {
         color: $white;
      }
	}
   &.no-photo {
      @include size(100%, 15vh);
   	@include tablet {
   		@include size(100%, 110px);
   	}
      @include desktop {
   		@include size(100%, 135px);
         max-width: $desktop-medium;
   	}
      @include md {
      	@include size(100%, 160px);
         max-width: $desktop-large;
   	}

   }
   &.no-photo .hero-text {
      top: initial;
      bottom: 0;
   }
   &.no-photo .hero-headline::after {
      display: none;
   }
	&-blurb {
		color: $text;
      max-width: $max-width-text;
		font-size: 1.1rem;
		line-height: 1.2;
		opacity: 1;	
		margin: 1.25rem auto 0;
      background: #e8e8e8;
      padding: 2rem;
		@include tablet {
			font-size: 1.1rem;
		}		
		@include desktop {
			font-size: 1.2rem;
		}		
		p {
			margin-bottom: 0;
		}
	}	

   &-grad {
   	@include size(100%, 100%);	
		  @include absolute(left 0 top 0);
		  opacity: 1;
		  z-index: 2;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#222222+0,222222+100&0+60,0.7+100 */
    background: -moz-linear-gradient(top,  rgba(34,34,34,0) 0%, rgba(34,34,34,0) 60%, rgba(34,34,34,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(34,34,34,0) 0%,rgba(34,34,34,0) 60%,rgba(34,34,34,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(34,34,34,0) 0%,rgba(34,34,34,0) 60%,rgba(34,34,34,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00222222', endColorstr='#b3222222',GradientType=0 ); /* IE6-9 */
    @include tablet-wide {
     /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#222222+0,222222+100&0+60,0.7+100 */
     background: -moz-linear-gradient(top,  rgba(34,34,34,0) 0%, rgba(34,34,34,0) 60%, rgba(34,34,34,0.7) 100%); /* FF3.6-15 */
     background: -webkit-linear-gradient(top,  rgba(34,34,34,0) 0%,rgba(34,34,34,0) 60%,rgba(34,34,34,0.7) 100%); /* Chrome10-25,Safari5.1-6 */
     background: linear-gradient(to bottom,  rgba(34,34,34,0) 0%,rgba(34,34,34,0) 60%,rgba(34,34,34,0.7) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
     filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00222222', endColorstr='#b3222222',GradientType=0 ); /* IE6-9 */
    }
   }
   &-photo {
		@include size(100%, 100%);	
		@include absolute(left 0 top 0);
		opacity: 1;
		z-index: 1;
		&-pic {
			@include size(100%);	
			object-fit: cover;
			overflow: hidden;	
			@include tablet-wide {
			 			@include absolute(left 0 top 0);
			}
         &.opacity-70 {
            opacity: .7;
         }
      }
		&-img {
			@include size(100%);	
			object-fit: cover;
		}				
	}

 & .hero-photo {
   position: relative;
		 @include desktop {
		   position: absolute;
		 }
 }

}

.hero.blue .hero-grad {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#222222+0,222222+100&0+60,0.7+100 */
  background: -moz-linear-gradient(top,  rgba(9,54,96,0) 0%, rgba(9,54,96,0) 60%, rgba(9,54,96,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  rgba(9,54,96,0) 0%,rgba(9,54,96,0) 60%,rgba(9,54,96,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  rgba(9,54,96,0) 0%,rgba(9,54,96,0) 60%,rgba(9,54,96,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00093660', endColorstr='#b3093660',GradientType=0 ); /* IE6-9 */
  @include tablet-wide {
   /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#222222+0,222222+100&0+60,0.7+100 */
   background: -moz-linear-gradient(top,  rgba(9,54,96,0) 0%, rgba(9,54,96,0) 60%, rgba(9,54,96,0.7) 100%); /* FF3.6-15 */
   background: -webkit-linear-gradient(top,  rgba(9,54,96,0) 0%,rgba(9,54,96,0) 60%,rgba(9,54,96,0.7) 100%); /* Chrome10-25,Safari5.1-6 */
   background: linear-gradient(to bottom,  rgba(9,54,96,0) 0%,rgba(9,54,96,0) 60%,rgba(9,54,96,0.7) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
   filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00093660', endColorstr='#b3093660',GradientType=0 ); /* IE6-9 */
  }
}

.hero.black .hero-grad {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#222222+0,222222+100&0+60,0.7+100 */
  background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0) 60%, rgba(0,0,0,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0) 60%, rgba(0,0,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 60%, rgba(0,0,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#b3000000',GradientType=0 ); /* IE6-9 */
  @include tablet-wide {
   /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#222222+0,222222+100&0+60,0.7+100 */
   background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0) 60%, rgba(0,0,0,0.7) 100%); /* FF3.6-15 */
   background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0) 60%, rgba(0,0,0,0.7) 100%); /* Chrome10-25,Safari5.1-6 */
   background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%, rgba(0,0,0,0) 60%, rgba(0,0,0,0.7) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
   filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#b3000000',GradientType=0 ); /* IE6-9 */
  }
}


.hero.orange .hero-grad {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#222222+0,222222+100&0+60,0.7+100 */
  background: -moz-linear-gradient(top,  rgba(237,112,0,0) 0%, rgba(237,112,0,0) 60%, rgba(237,112,0,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  rgba(237,112,0,0) 0%, rgba(237,112,0,0) 60%, rgba(237,112,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(237,112,0,0) 0%, rgba(237,112,0,0) 60%, rgba(237,112,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ed7000', endColorstr='#b3ed7000',GradientType=0 ); /* IE6-9 */
  @include tablet-wide {
   /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#222222+0,222222+100&0+60,0.7+100 */
   background: -moz-linear-gradient(top, rgba(237,112,0,0) 0%, rgba(237,112,0,0) 60%, rgba(237,112,0,0.7) 100%); /* FF3.6-15 */
   background: -webkit-linear-gradient(top, rgba(237,112,0,0) 0%, rgba(237,112,0,0) 60%, rgba(237,112,0,0.7) 100%); /* Chrome10-25,Safari5.1-6 */
   background: linear-gradient(to bottom, rgba(237,112,0,0) 0%, rgba(237,112,0,0) 60%, rgba(237,112,0,0.7) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
   filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ed7000', endColorstr='#b3ed7000',GradientType=0 ); /* IE6-9 */
  }
}
