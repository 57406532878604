#heroPG {
   margin: 0 auto;
   margin-top: 70px;
   position: relative;
   display: block;
   max-width: 100%;
   background: $darkgray;
   @include tablet-wide {
      margin-top: 90px;
      margin-bottom: -2rem;
   }
}

.hero-slider {
   display: block;
   overflow: hidden;
   background: $darkgray;
   padding: 0rem;
   max-width: $desktop;
   @include tablet {
     padding: 0;
     margin: 0 auto;
   }
   @include tablet-wide {
     @include size(100%, 500px);
   }
   @include desktop {
     @include size(100%, 550px);
   }
   @include md {
     @include size(100%, 600px);
   }
}

.slide {
  background-color: $darkgray;
  @include size(100%, auto);	
  position: relative;
  margin: 0 auto;
  transition: $slow;
  overflow: hidden;
  @include tablet {
    @include size(100%, 450px);	
    overflow: visible;
  }
  @include tablet-wide {
    @include size(100%, 500px);
  }
  @include desktop {
    @include size(100%, 550px);
    max-width: $desktop-medium;
  }
  @include md {
    @include size(100%, 600px);
    max-width: $desktop-large;
  }
} 

.heroPG-item {
   &-photo {
      width: 100%;
      height: 300px;
      top: 0px;
      left: 0px;
      position: relative;
      overflow: hidden;
      transition: $slow;
      @include tablet {
      		@include size(100%, 100%);	
      		@include absolute(left 0 top 0);
      		opacity: 1;
      		z-index: 1;
        overflow: visible;
      }
		&-pic {
   			@include size(100%);	
   			@include absolute(left 0 top 0);
   			object-fit: cover;
   			overflow: hidden;	
      transition: $slow;
  }
		&-img {
 			@include size(100%);	
 			object-fit: cover;
    transition: $veryslow;
    display: block;
 		}				
	}
   
   &:first-child {
      height: 300px;
      @include tablet {
   		height: 450px;
   	}
      @include tablet-wide {
         height: 500px;
         -webkit-box-shadow: 0 0 40px 0 rgba(0,0,0,.85);
         box-shadow: 0 0 40px 0 rgba(0,0,0,.85); 
         z-index: 200;
      }
      @include desktop {
   		height: 550px;
   	}
      @include md {
      	height: 600px;
   	}
      @include lg {
         -webkit-box-shadow: none;
         box-shadow: none;          
      }
      &::after {
         @include tablet { 
         }
         @include lg {
         }
      } 
      & .hero-item-grad {
         display: block;
      }
      & .hero-item-photo {
      	@include size(100%, 100%);	
   		@include absolute(left 0 top 0);
   		opacity: 1;
   		z-index: 1;
         overflow: visible;
      }
   }
   &:nth-child(2) {
      padding: 0;
      @include tablet {
         padding: 0;
         background: $clear;
      }
      & .hero-item-photo {
      }
      & .hero-item-container {
      }
      & .hero-item-photo-pic {
         opacity: 1.0;
      }
   }
   &:last-child {
      z-index: 100;
      @include tablet {
         -webkit-box-shadow: none;
         box-shadow: none;          
      }
   }
}

/* -------- Slide Controls -------- */

.slide-controls {
	cursor: pointer;
	font-size: 1.8em;
	position: absolute; 
	top: 30%; 
	transform: scale(1.5);
	z-index: 101;
	color: #fff;
	opacity: 1;
	-webkit-transition: all .2s ease-in-out;
	-moz-transition:    all .2s ease-in-out;
	-o-transition:      all .2s ease-in-out;
	-ms-transition:     all .2s ease-in-out;
	transition:         all .2s ease-in-out;   
	-moz-transform:    translateY(-50%);
	-ms-transform:     translateY(-50%);
	-webkit-transform: translateY(-50%);
	transform:         translateY(-50%); 
	@include tablet {
   font-size: 1.4em; 
   top: 50%; 
   opacity: .6;
	}
}  
.slide-controls:hover {
	opacity: 1;	
}
.prev-slide { left: 1em; fill: $white;}
.next-slide { right: 1em; fill: $white;}



