.grid-layer {
	max-width: $max-width;
	width: 100%;
	margin: 0 auto;
	@extend %block-padding;
	@include phone {
	  padding-left: 1.15rem;
	  padding-right: 1.15rem;
	}	
	@include tablet {
		width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
	}
	&.dark-gray {
	  background: $darkgray;
	  color: $white;
  	h2 {
  		color: $white;
  	}
	}
	&.light-gray {
	  background: $lightestgray;
	}	
	&.blue {
	  background: $secondary;
	  color: $white;
  	h2 {
  		color: $white;
  	}	  
	}	
	&.green {
	  background: $primary;
	  color: $white;
  	h2 {
  		color: $white;
  	}	  
	}	
	@include desktop {
	}	
	&-col {
		@include tablet {
			padding: 0 .5rem;
		}
		@include desktop {
			padding: 0 1rem;		
		}		
		@include lg {
			padding: 0 1.5rem;		
		}		
		&:last-of-type { 
		  @include phone {
		    padding-top: 0;
		  }		  
		}	    
	}
	&.full-width {
   max-width: $max-width-text;
   &.grid-33 {
     max-width: 100%;
   }
	}
	&.min-padding {
  	@extend %block-padding-small;
	}
	&.no-top-padding,
	&.no_top_padding {
  	padding-top: 0 !important;
	}	
		&.structure-2080 {
	   /* Setup Flexbox */
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  /* Reverse Column Order */
  -webkit-flex-flow: column-reverse;
  flex-flow: column-reverse;
		}
	@include tablet {
  	&.structure {
  	  &-5050 {
  			.grid-layer-col:first-of-type,
  			.grid-layer-col:last-of-type { 
  			  width: 50%;
  			}	    
  	  }
  	  &-6733 {
  			.grid-layer-col:first-of-type {
  			  width: 67%;
  			}
  			.grid-layer-col:last-of-type { 
  			  width: 33%;
  			}	    
  	  }	  
  	  &-3367 {
  			.grid-layer-col:first-of-type {
  			  width: 33%;
  			}
  			.grid-layer-col:last-of-type { 
  			  width: 67%;
  			}	    
  	  }		  
    &-2080 {
      -webkit-flex-flow: initial;
      flex-flow: initial;
    		.grid-layer-col:first-of-type {
    		  width: 30%;
     		 @include tablet-wide {
     		  width: 20%;
         padding: 0 1rem 0 0;
     		 }
    		}
    		.grid-layer-col:last-of-type { 
    		  width: 70%;
     		 @include tablet-wide {
     		  width: 80%;
         padding: 0 0 0 2rem;
     		 }
    		}	    
  	  }		  
  	}
	}
}
.text-layer {
  .grid-layer {
    padding: 0;
  }
}

