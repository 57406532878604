.story-layers-item {
   width: 100%;
   max-width: $desktop;
   margin: 0 auto;
   text-align: left;
   display: block;
   &.full-width {
      max-width: 100%;
   }
   &.padding-top {
      @extend %block-padding-top;
   }
   &.padding-bottom {
      @extend %block-padding-bottom;
   }
   & h1 {
      position: relative;
      display: block;
      margin-bottom: 2rem;
      z-index: 1;
      text-align: center;
      &.headline-black {
         color: $black;
      }
      &.headline-white {
         color: $white;
      }
      &.headline-dark-gray {
         color: $darkgray;
      }
      &.headline-blue {
         color: $blue;
      }
      &.headline-orange {
         color: $orange;
      }
      @include tablet-wide {
         text-align: left;
         display: inline-block;
      }
   }
   & .description {
      display: block;
      font-size: 1.0rem;
      text-align: center;
      margin-bottom: 1.5rem;
      @include tablet-wide {
         font-size: 1.2rem;
         text-align: left;
      }
   }
   &.dark-gray .story-layers-item-block-grid, &.dark-gray .story-layers-item-block-text { color: white; }
   &.black .story-layers-item-block-grid, &.black .story-layers-item-block-text { color: white; }
   &.red .story-layers-item-block-grid, &.red .story-layers-item-block-text { color: white; }

   &-block {
      display: block;
      width: 100%;
      max-width: $desktop;
      margin: 0 auto;
      padding: 0;
      &.contents_full_width {
         max-width: 100%;
         @include md {
            max-width: $desktop-medium;
         }
      }
      &-grid {
         display: flex;
         text-align: left;
         flex-wrap: wrap;
         justify-content: space-evenly;
         align-content: flex-start;
         width: 100%;
         max-width: $desktop;
         margin: 0 auto;
         padding: 0;
         &.contents_full_width {
            max-width: 100%;
            @include md {
               max-width: $desktop-medium;
         	}
         }
         &-photo {
            width: 100%;
            @include tablet {
               width: 50%;
            }
            & img {
               position: relative;
               top: 50%;
               width: 100%;
               -ms-transform: translateY(-50%);
               -webkit-transform: translateY(-50%);
               transform: translateY(-50%);             
            }
         }
         &-text {
            position: relative;
            width:  100%;
            @include tablet {
             width: 45%;
            }
            &-centering {
               position: relative;
               top: 50%;
               -ms-transform: translateY(-50%);
               -webkit-transform: translateY(-50%);
               transform: translateY(-50%);
            }
         }
         &.left {
            & .story-layers-item-block-grid-photo {
               order: 1;
               margin-right: 5%;
            }
            & .story-layers-item-block-grid-text {
               order: 2;
            }
         }
         &.center {
            & .story-layers-item-block-grid-photo {
               order: 1;
               width: 100%;
               & img {
                 max-width: 100%;
                 height: auto;
                 position: relative;
               }
            }
            & .story-layers-item-block-grid-text {
               order: 2;
               width: 100%;
               padding: 2rem;
               position: relative;
               @include tablet {
                 padding: 4rem;
               }
            }
         }
         &.right {
            & .story-layers-item-block-grid-photo {
               order: 2;
               margin-left: 5%;
            }
            & .story-layers-item-block-grid-text {
               order: 1;
            }
         }
         
      }   
   }
}
