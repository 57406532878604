#team-layer {
 & h1 {
  color: $orange;
  &.committee-members-sublist {
    font-size: 150%;
    margin-left: 0;
  }
 }
}

.team-block-container {
  margin: 0 0 4rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  align-content: flex-start;
  &.sponsor { }
  &.committee-members { }
  &.committee-members-sublist {
    margin-left: 0;
  }
  &.staff { }
}

.team-member {
 padding: 1rem;
 width: 100%;
 &-link {
  
 }
 
 &-photo {
  width: 7rem;
  height: 7rem;
  &-img {
   width: 100%;
   height: 100%;
   background-size: cover;
   background-repeat: no-repeat;
   -webkit-border-radius: 50%;
   border-radius: 50%;
  }
 }
 
 &-text {
  
  &-headline {
 		font-family: $headline2;
 		font-size: 1.4rem;
 		line-height: 1.1;
 		color: $primary;
 		margin-bottom: .5rem;
 		@extend %headline2;
 		text-transform: none;
  }
  
  &-title {
   color: $medgray;
 		line-height: 1.1;
 		text-transform: uppercase;
 		font-size: .9rem;
 		font-weight: 700;
 		display: block;
  }
  
  &-subtext {
   color: $black;
 		font-size: .9rem;
 		line-height: 1.2;
 		margin-top: .25rem; 
 		display: block;
  }
 
 } 
 
}

.team-block-container.sponsor .team-member {
 display: flex;
 flex-direction: row;
 flex-wrap: nowrap;
 justify-content: flex-start;
 align-items: center;
 @include desktop {
  width: 50%;
 }
 &-text {
  padding: 0 0 0 1.0rem;
  &-headline {
 		font-family: $headline2;
 		font-size: 2rem;
  }
  
  &-title {
 		font-size: 1rem;
  }
  
  &-subtext {
 		font-size: 1rem;
  }
 
 } 
}
.team-block-container.committee-members .team-member {
 @include desktop {
  width: 50%;
 }
}
.team-block-container.committee-members-sublist .team-member {
 @include desktop {
  width: 50%;
 }
}
.team-block-container.staff .team-member {
 @include desktop {
  width: 33%;
 }
}
